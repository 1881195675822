<template>
        <Cases :auditCases="true"></Cases>
</template>

<script>
import Cases from "./Cases/CasesTable.vue"
export default {
        name: "Audits",
        components: {
                Cases
        }
}
</script>